namespace Invaulta {

    export function Setup() {
        $(".modal").on('shown.bs.modal', function () {
            $(this).find("input:visible:first").focus();
        });

        var messages = $(".message-to-display");
        if (messages.length > 0) {

            messages.each(function (index) {
                var title = $(this).data("title");
                var content = $(this).data("content");
                var type = $(this).data("type");
                var icon = "fa fa-bullhorn";
                var colour = "#3276B1";

                switch (type) {
                    case "Success":
                        title = title || "Success";
                        icon = "fa fa-check-circle-o";
                        colour = "#739e73";
                        break;

                    case "Error":
                        title = title || "Error";
                        icon = "fa fa-exclamation-circle";
                        colour = "#c26565";
                        break;

                    case "Information":
                        title = title || "Information";
                        break;

                }

                $.smallBox({
                    title: title,
                    content: content,
                    color: colour,
                    icon: icon,
                    iconSmall: '',
                    timeout: 30000,
                    sound_file: ''
                });
            });
        }
    }

    export function ShowSuccessMessage(content: string, title: string = "Success") {
        showMessage(title, content, "#739e73", "fa fa-check-circle-o");
    }

    export function ShowErrorMessage(content: string, title: string = "Error") {
        showMessage(title, content, "#c26565", "fa fa-exclamation-circle");
    }

    export function ShowInfoMessage(content: string, title: string = "Information") {
        showMessage(title, content, "#3276B1", "fa fa-bullhorn");
    }

    function showMessage(title: string, content: string, colour: string, icon: string, iconSmall: string = '', timeOut: number = 30000) {
        $.smallBox({
            title: title,
            content: content,
            color: colour,
            icon: icon,
            iconSmall: iconSmall,
            timeout: timeOut,
            sound_file: ''
        });
    }

    export function HideLoading() {
        $("#loading").hide();
    }

    export function ShowLoading() {
        $("#loading").show();
    }

    export function Confirm(question: string, title: string, yesCallback: Function, noCallback: Function = null, yesLabel: string = "Yes", noLabel: string = "No") {
        title = title || "Confirm";
        var id = 'autoModal';
        $("body").remove("#" + id);
        var modal = $('\
<div id=' + id + ' class="modal fade" tabindex="-1" role="dialog"> \
    <div class="modal-dialog" role="document"> \
        <div class="modal-content"> \
            <form method="post" action=""> \
                <div class="modal-header"> \
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> \
                    <h4 class="modal-title">' + title + '</h4> \
                </div> \
                <div class="modal-body">' + question + '</div> \
                <div class="modal-footer"> \
                    <button type="button" class="btn btn-default modal-no" data-dismiss="modal">' + noLabel + '</button> \
                    <button type="button" class="btn btn-primary modal-yes" data-dismiss="modal">' + yesLabel + '</button> \
                </div> \
            </form> \
        </div> \
    </div> \
</div>');

        var hadAction = false;

        modal.on("click", ".modal-yes", function (e) {
            e.preventDefault();
            hadAction = true;
            if (yesCallback) yesCallback();
        });

        modal.on("click", ".modal-no", function (e) {
            e.preventDefault();
            hadAction = true;
            if (noCallback) noCallback();
        });

        modal.on('hidden.bs.modal', function () {
            modal.remove();

            if (!hadAction) {
                hadAction = true;
                if (noCallback) noCallback();
            }
        });

        modal.modal('show');
    }


    export enum VaultStatusTypes {
        Offsite,
        Onsite,
        PermRel,
        TempRel,
        Requested
    }

    export enum VaultRequestStatusTypes {
        Requested,
        Completed
    }

    export enum VaultRequestDeliveryTypes {
        Standard,
        Priority
    }

    export enum VaultShareAccessTypes {
        ReadOnly,
        Full
    }

    export enum VaultChangeTypes {
        VaultAdded,
        VaultUpdated,
        VaultItemAdded,
        VaultItemUpdated,
        VaultItemMetaDataUpdated,
        VaultItemMerged,
        VaultRequested,
        VaultReleased,
        VaultItemReleased,
        VaultFileAdded,
        VaultFileUpdated
    }

    export enum GenderType {
        m,
        f,
        u
    }

    export enum PermissionAccessLevels {
        None,
        UserOnly,
        Read,
        Write,
        Lock,
        Admin
    }

    export enum TemplateValueTypes {
        contact,
        date,
        org,
        text
    }

    export enum AddressTypes {
        Main,
        Postal
    }

    export enum EmailTypes {
        Main,
        Work,
        Home,
        Other
    }

    export enum PhoneTypes {
        Home,
        Work,
        Mobile,
        Other,
        Fax,
        Pager
    }


}
